.cursusAdd {
  background: #ffffff;
  box-shadow: -11px 0px 40px -20px rgba(140, 151, 175, 0.4);
  border-radius: 20px;
  min-width: 500px;
  .form-cursus {
    div:nth-child(even) {
      margin-left: 10px;
      margin-right: 10px;
    }
    .buttons {
      button {
        min-width: 40px;
        &:nth-child(1) {
          margin-right: 10px;
        }
      }
    }
  }
  .check {
    font-size: 16px;
    line-height: 16px;
    color: #a6a9c8;
    &:first-child {
      margin-left: 6px;
    }
  }
}
.check2 {
  line-height: 16px;
  color: #696ebe;

  margin-left: 12px;
}

.modalSites {
  input {
    min-width: 100%;
    height: 45px;
    padding: 10px 10px 11.5px 37px;
    border-radius: 9px;
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(166, 169, 200, 0.28);
    &:nth-child(even) {
      margin-bottom: 10px;
    }
  }
}
.flexCenterBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-gap: 16px;
}
