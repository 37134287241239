.historiques{
    .card-histoique {
        min-width: 641px;
        max-width: 641px;
        margin: 20px 20px 20px 0px;
        box-shadow: -11px 0px 40px -20px rgba(140, 151, 175, 0.4);
        background: #ffffff;
        border-radius: 20px;
    }
}
