.modal-end-consultation {
  padding: 74.9px 61.9px 42.8px 62.9px;
  .menu {
    color: var(--color-primary-grey);
  }
}

.rdw-editor-wrapper {
  display: flex !important;
  flex-direction: column-reverse !important;
  height: 100% !important;
}

.rdw-editor-main {
  height: 95%;
  overflow: auto;
}

.rdw-editor-toolbar {
  justify-content: center !important;
}
.shared-folder {
  select {
    -webkit-appearance: none;
    appearance: none;
  }
  .select-wrapper {
    position: relative;
  }

  .select-wrapper::after {
    content: "▼";
    font-size: 1rem;
    top: 25%;
    right: 10px;
    color: grey;
    position: absolute;
  }

  .box-share-forder {
    flex-grow: 0;
    padding: 8px 29px 8px 29px;
    border-radius: 9px;
    background-color: rgba(166, 169, 200, 0.22);
    font-size: 14px;
    color: var(--color-primary-grey);
  }

  .box-selected {
    border: 1px solid var(--color-primary-blue);
    background-color: var(--color-primary-grey-background);
    color: var(--color-primary-blue);
  }
  .report-card {
    padding: 11px 12px 8px 10px;
    border-radius: 20px;
    background-color: #f2f2f7;
    width: 300px;
  }

  .updated-report {
    font-size: 13px;
    font-weight: bold;
    color: #a6a9c8;
  }
}

.share-folder-antecedent {
  margin-top: 30px;
  border-radius: 20px;
  padding: 11px 12px 30px 19px;
  box-shadow: -11px 5px 40px -20px rgba(140, 151, 175, 0.4);
  background-color: #f5f5f9;
  .card-antecedent {
    width: 300px;
    padding: 20px 6px 0 20px;
    border-radius: 20px;
    box-shadow: -11px 0 40px -20px rgba(140, 151, 175, 0.4);
    background-color: #fff;
  }
}
.blue-text {
  font-size: 16px;
  font-weight: 500;
  color: #4565f6;
}

.share-folder-traitement {
  margin-top: 30px;
  border-radius: 20px;
  padding: 11px 12px 30px 19px;
  box-shadow: -11px 5px 40px -20px rgba(140, 151, 175, 0.4);
  background-color: #f5f5f9;
  .card-traitement {
    width: 300px;
    padding: 10px 11.9px 12px 15px;
    border-radius: 20px;
    box-shadow: -11px 5px 40px -20px rgba(140, 151, 175, 0.4);
    background-color: #fff;
  }
  .blue-text {
    font-size: 16px;
    font-weight: 500;
    color: #4565f6;
  }
}
.sub-title2 {
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
  color: #827f9b;
}
.request-modal-info {

  margin: 20px 20px 20px 0px;
  
  background: #ffffff;
  border-radius: 20px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .card-specialist {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    max-width: 85vw;
    min-height: 123px;
    border-radius: 20px;
    background-color: #fff;
  }
  .sub-container {
    margin: 20px;
    .header {
      .infos {
        background: #f5f5f9;
        border-radius: 20px;
        .sub-infos {
          .details {
            font-size: 13px;
            margin-top: 20px;
            .name {
              height: 23px;
              font-weight: bold;
              font-size: 20px;
              color: #000000;
            }
            .phonemail {
              &.div:first-child {
                margin-right: 30px;
              }
            }
            .mail {
              margin-left: 31px;
            }
            .birth {
              margin-bottom: 10px;
              .sub-birth {
                img {
                  width: 17px;
                  height: 17px;
                }
              }
              .dateBirth {
                p {
                  font-size: 16px;
                  font-weight: 400;
                }
              }
            }
          }
          .photo {
           
           
            margin: 20px 15px 20px 20px;
            border-radius: 50%;
            svg {
              width: 100px;
              height: 100px;
              
            }
          }
        }
        .patient-icons {
          margin: 10px 12.5px 10px 12.5px;
        }
      }
    }
    .taches {
      overflow: hidden;
      margin-bottom: 45px;
      justify-content: space-between;
      flex-wrap: wrap;
      .tache {
        width: 266px;
        background: #ffffff;
        box-shadow: -11px 5px 40px -20px rgba(140, 151, 175, 0.4);
        border-radius: 20px;
        .header {
          margin-left: 20px;
          margin-right: 20px;
          margin-top: 7px;
          p {
            span {
              font-weight: 400;
            }
          }
          .sub-header {
            font-weight: bold;
            font-size: 18px;
            line-height: 21px;
          }
        }
        .subTitle {
          font-size: 13px;
          line-height: 15px;
          color: #a6a9c8;
          margin-left: 20px;
          margin-right: 20px;
        }
        .subTitle-doc {
          font-size: 13px;
          line-height: 15px;
          color: #a6a9c8;
          margin: 18px 20px 0px 20px;
        }
        .btn {
          margin-left: 12px;
        }
      }
      .tache-box {
        font-size: 14px;
        line-height: 16px;
        color: #a6a9c8;
        height: 35px;
        background: rgba(166, 169, 200, 0.22);
        border-radius: 9px;
        padding: 10px 15px 9px 14px;
        margin: 10px 0px 13px 20px;
      }
      .tacheboxContainer {
        margin-left: 20px;
        .tache-box {
          font-size: 14px;
          line-height: 16px;
          color: #a6a9c8;
          height: 35px;
          background: rgba(166, 169, 200, 0.22);
          border-radius: 9px;
          padding: 10px 15px 9px 14px;
          margin: 10px 0px 13px 0px;
          &:nth-child(odd) {
            margin-right: 11px;
          }
          p {
            margin-left: 7px;
          }
        }
      }
      .addObservation {
        color: #4565f6;
      }
      .traitementbtn {
        margin-top: 60px;
      }
    }
    .patient-tabs {
      background: #ffffff;
      border: 1px solid #eaecf4;
      border-radius: 15px;
      margin-bottom: 20px;
      .tabs {
        min-height: 50px;
        .tab {
          height: 42px;
          padding: 10px 12px 10px 12px;
        }
        .selected {
          background: #f5f5f9;
          border-radius: 15px;
          padding: 10px 12px 10px 12px;
          color: #4565f6;
        }
      }
    }
    .tabs-content {
      background: #f5f5f9;
      // box-shadow: -11px 5px 40px -20px rgba(140, 151, 175, 0.4);
      border-radius: 20px 20px 20px 20px;
      //height: 347px;
      padding: 20px 20px 20px 20px;
      .tabs-container {
        .tabs-liste {
          .item {
            .sub-item {
              &:nth-child(2) {
                margin-top: 38px;
                margin-bottom: 38px;
              }
              .title-container {
                line-height: 16px;
                .title {
                  font-weight: bold;
                  font-size: 20px;
                  line-height: 23px;
                }
                .card-subtitle {
                  margin-bottom: 8px;
                }
              }
              .values-patient {
                .sub-values {
                  margin-right: 50px;
                  margin-top: 8px;
                  .value {
                    background: #ffffff;
                    border-radius: 10px;
                    width: 47px;
                    height: 37px;
                    font-weight: bold;
                    font-size: 13px;
                    line-height: 15px;
                    color: #4565f6;
                    margin-left: 6px;
                    margin-right: 6px;
                  }
                  .name {
                    font-size: 16px;
                    line-height: 18px;
                  }
                }
                .imc {
                  p {
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 18px;
                    color: #a6a9c8;
                  }
                  .valImc {
                    width: 47px;
                    height: 37px;
                    background: #a6a9c8;
                    border-radius: 10px;
                    p {
                      font-weight: bold;
                      font-size: 13px;
                      line-height: 15px;
                      color: white;
                      padding: 11px 9px 11px 9px;
                    }
                  }
                }
              }
            }
          }
          .sub-documents {
            .doc-title {
              font-size: 20px;
              text-transform: uppercase;
              color: #827f9b;
              margin-bottom: 8px;
            }
            .images {
              img {
                width: 120px;
                height: 120px;
                border: 0.5px solid #dbdbdb;
                border-radius: 8px;
                &:nth-child(n-1) {
                  margin-right: 29px;
                }
              }
            }
            .title-doc {
              margin-top: 35px;
              .sub-title {
                font-size: 20px;
                line-height: 23px;
                text-transform: uppercase;
                color: #827f9b;
              }
              .btnAddtache {
                width: 103px;
                height: 32px;
              }
            }
          }
        }
      }
    }
  }
  .img {
    margin-right: 9px;
  }
  .title-taches {
    margin-top: 52px;
    margin-bottom: 37px;
    .sub-title {
      font-size: 20px;
      line-height: 23px;
      text-transform: uppercase;
      color: #827f9b;
    }
    .btnAddtache {
      width: 103px;
      height: 35px;
    }
  }
  .title-taches-inside-block {
    margin-bottom: 37px;
    .sub-title {
      font-size: 20px;
      line-height: 23px;
      text-transform: uppercase;
      color: #827f9b;
    }
    .btnAddtache {
      width: 103px;
      height: 35px;
      margin-right: 9px;
    }
    .alignedIcon {
      width: 32px;
      height: 32px;
      border-radius: 9px;

    }
  }
  .fleche {
    width: 32px;
    height: 32px;
    background: #ffffff;
    border-radius: 9px;
    margin-right: 12px;
  
  }
  .stretch {
    margin-top: 46px;
  }
}
