input:focus,
textarea:focus,
select:focus {
  outline: none;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: var(--color-primary-blue);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px var(--color-primary-blue);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.iconClass {
  div:first-child{
    position: absolute;
    padding: 7px 0px 15px 8px;
  }
}
.icona{
  position: absolute;
  height:15px;
  vertical-align: middle;
  
}
.hello{
  position: relative;
  padding-left: 9%;
 bottom: 5px;
}
.imgClass{
  div:first-child{
    width: 16px;
    height: 17px;
    margin: 15px 0px 0px 14px;
    position: absolute;
    img{
      width: 15px;
      height: 15px;
    }
  }
}

