.sidebar-doctor,
.sidebar-patient {
  .selected-menu {
    background-color: #fff;
    max-width: 240px;
    border-radius: 20px;
    svg {
      filter: invert(36%) sepia(95%) saturate(3055%) hue-rotate(220deg)
        brightness(98%) contrast(97%) !important;
    }
    * {
      color: var(--color-primary-blue) !important;
    }
  }
}

.sidebar-container {
  width: 250px;
}
.avatar-doctor {
  border-radius: 15px;
  // background-color: #f08b60;
  width: 50px;
  height: 50px;
}
.docter-center {
  font-size: 12px;
  max-width: 90px;
  color: #a6a9c8;
}

.notif-text{
  font-size: 12px;
  color:#F16449
}

.mobile-sidebar {
  background-color: var(--color-primary-grey-background);
  z-index: 10;
  height: 100vh;
  position: fixed !important;
  top: 0;
  left: 0;
  padding: 56px 28px;
  width: 80vw;
  box-shadow: 4px 0 10px 0 #0004;
}

.swipe-events-container {
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 20vw;
}

.swipe-events-container.swipeable-fullscreen {
  width: 90vw;
}

.mobile-menu-burger {
  top: -56px;
  right: -80px;
}
.notification-list {
  display: none;
}

.notification-list {
  z-index: 99 !important;
  opacity: 10;
}

// .notification {
//   border: 2px solid red;
//   &:hover {
//     background-color: red;
//   }
// }

// .notification:hover + .notification-list {
//   display: block;
//   z-index: 999;
//   opacity: 1;
// }

.notification {
  &:hover .notification-list {
    display: block;
    z-index: 999;
    opacity: 1;
  }
}
