@import "~react-datepicker/dist/react-datepicker.css";
.my-patients {
  .cros {
    padding-left: 9px;
    font-size: 9px;
  }
  .btnAddPatient {
    width: 182px;
    height: 50px;
  }
  .btnAddFiltre {
    width: 146px;
    height: 35px;
  }
  .filtres {
    height: 35px;
  }
  .patient-list {
    margin-top: 29px;
  }
}
.label {
  position: relative !important;
  bottom: 18px !important;
  padding-right: 5px !important ;
  padding-left: 5px !important ;
}
.radioItem {
  width: 22px;
}
.sub-title2 {
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
  color: #827f9b;
}
.request-modal-info {
  margin: 20px 20px 20px 0px;

  background: #ffffff;
  border-radius: 20px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .sub-container {
    margin: 20px;
    .header {
      .infos {
        background: #f5f5f9;
        border-radius: 20px;
        .sub-infos {
          .details {
            font-size: 13px;
            margin-top: 20px;
            .name {
              height: 23px;
              font-weight: bold;
              font-size: 20px;
              color: #000000;
            }
            .phonemail {
              &.div:first-child {
                margin-right: 30px;
              }
            }
            .mail {
              margin-left: 31px;
            }
            .birth {
              margin-bottom: 10px;
              .sub-birth {
                img {
                  width: 17px;
                  height: 17px;
                }
              }
              .dateBirth {
                p {
                  font-size: 16px;
                  font-weight: 400;
                }
              }
            }
          }
          .photo {
            margin: 20px 15px 20px 20px;
            border-radius: 50%;
            svg {
              width: 100px;
              height: 100px;
            }
          }
        }
        .patient-icons {
          margin: 10px 12.5px 10px 12.5px;
        }
      }
    }
    .taches {
      overflow: hidden;
      margin-bottom: 45px;
      justify-content: space-between;
      flex-wrap: wrap;
      .tache {
        width: 266px;
        background: #ffffff;
        box-shadow: -11px 5px 40px -20px rgba(140, 151, 175, 0.4);
        border-radius: 20px;
        .header {
          margin-left: 20px;
          margin-right: 20px;
          margin-top: 7px;
          p {
            span {
              font-weight: 400;
            }
          }
          .sub-header {
            font-weight: bold;
            font-size: 18px;
            line-height: 21px;
          }
        }
        .subTitle {
          font-size: 13px;
          line-height: 15px;
          color: #a6a9c8;
          margin-left: 20px;
          margin-right: 20px;
        }
        .subTitle-doc {
          font-size: 13px;
          line-height: 15px;
          color: #a6a9c8;
          margin: 18px 20px 0px 20px;
        }
        .btn {
          margin-left: 12px;
        }
      }
      .tache-box {
        font-size: 14px;
        line-height: 16px;
        color: #a6a9c8;
        height: 35px;
        background: rgba(166, 169, 200, 0.22);
        border-radius: 9px;
        padding: 10px 15px 9px 14px;
        margin: 10px 0px 13px 20px;
      }
      .tacheboxContainer {
        margin-left: 20px;
        .tache-box {
          font-size: 14px;
          line-height: 16px;
          color: #a6a9c8;
          height: 35px;
          background: rgba(166, 169, 200, 0.22);
          border-radius: 9px;
          padding: 10px 15px 9px 14px;
          margin: 10px 0px 13px 0px;
          &:nth-child(odd) {
            margin-right: 11px;
          }
          p {
            margin-left: 7px;
          }
        }
      }
      .addObservation {
        color: #4565f6;
      }
      .traitementbtn {
        margin-top: 60px;
      }
    }
    .patient-tabs {
      background: #ffffff;
      border: 1px solid #eaecf4;
      border-radius: 15px;
      margin-bottom: 20px;
      .tabs {
        min-height: 50px;
        .tab {
          height: 42px;
          padding: 10px 12px 10px 12px;
        }
        .selected {
          background: #f5f5f9;
          border-radius: 15px;
          padding: 10px 12px 10px 12px;
          color: #4565f6;
        }
      }
    }
    .tabs-content {
      background: #f5f5f9;
      border-radius: 20px 20px 20px 20px;
      padding: 20px 20px 20px 20px;
      .tabs-container {
        .tabs-liste {
          .item {
            .sub-item {
              &:nth-child(2) {
                margin-top: 38px;
                margin-bottom: 38px;
              }
              .title-container {
                line-height: 16px;
                .title {
                  font-weight: bold;
                  font-size: 20px;
                  line-height: 23px;
                }
                .card-subtitle {
                  margin-bottom: 8px;
                }
              }
              .values-patient {
                .sub-values {
                  margin-right: 50px;
                  margin-top: 8px;
                  .value {
                    background: #ffffff;
                    border-radius: 10px;
                    width: 47px;
                    height: 37px;
                    font-weight: bold;
                    font-size: 13px;
                    line-height: 15px;
                    color: #4565f6;
                    margin-left: 6px;
                    margin-right: 6px;
                  }
                  .name {
                    font-size: 16px;
                    line-height: 18px;
                  }
                }
                .imc {
                  p {
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 18px;
                    color: #a6a9c8;
                  }
                  .valImc {
                    width: 47px;
                    height: 37px;
                    background: #a6a9c8;
                    border-radius: 10px;
                    p {
                      font-weight: bold;
                      font-size: 13px;
                      line-height: 15px;
                      color: white;
                      padding: 11px 9px 11px 9px;
                    }
                  }
                }
              }
            }
          }
          .sub-documents {
            .doc-title {
              font-size: 20px;
              text-transform: uppercase;
              color: #827f9b;
              margin-bottom: 8px;
            }
            .images {
              img {
                width: 120px;
                height: 120px;
                border: 0.5px solid #dbdbdb;
                border-radius: 8px;
                &:nth-child(n-1) {
                  margin-right: 29px;
                }
              }
            }
            .title-doc {
              margin-top: 35px;
              .sub-title {
                font-size: 20px;
                line-height: 23px;
                text-transform: uppercase;
                color: #827f9b;
              }
              .btnAddtache {
                width: 103px;
                height: 32px;
              }
            }
          }
        }
      }
    }
  }
  .img {
    margin-right: 9px;
  }
  .title-taches {
    margin-top: 52px;
    margin-bottom: 37px;
    .sub-title {
      font-size: 20px;
      line-height: 23px;
      text-transform: uppercase;
      color: #827f9b;
    }
    .btnAddtache {
      width: 103px;
      height: 35px;
    }
  }
  .title-taches-inside-block {
    margin-bottom: 37px;
    .sub-title {
      font-size: 20px;
      line-height: 23px;
      text-transform: uppercase;
      color: #827f9b;
    }
    .btnAddtache {
      width: 103px;
      height: 35px;
      margin-right: 9px;
    }
    .alignedIcon {
      width: 32px;
      height: 32px;
      border-radius: 9px;
    }
  }
  .fleche {
    width: 32px;
    height: 32px;
    background: #ffffff;
    border-radius: 9px;
    margin-right: 12px;
  }
  .stretch {
    margin-top: 46px;
  }
}
.patient-modal-info {
  max-width: calc(100vw - 40px) !important;
  width: 641px;
  margin: 20px 20px 20px 0px;
  padding: 20px;

  background: #ffffff;
  border-radius: 20px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .sub-container {
    margin: 20px;
    .header {
      .infos {
        background: #f5f5f9;
        border-radius: 20px;
        .sub-infos {
          .details {
            font-size: 13px;
            margin-top: 20px;
            .name {
              height: 23px;
              font-weight: bold;
              font-size: 20px;
              color: #000000;
            }
            .phonemail {
              &.div:first-child {
                margin-right: 30px;
              }
            }
            .mail {
              margin-left: 31px;
            }
            .birth {
              margin-bottom: 10px;
              .sub-birth {
                img {
                  width: 17px;
                  height: 17px;
                }
              }
              .dateBirth {
                p {
                  font-size: 16px;
                  font-weight: 400;
                }
              }
            }
            .birthPatient {
              margin-bottom: 1px;
              .sub-birth {
                img {
                  width: 17px;
                  height: 17px;
                }
              }
              .dateBirth {
                p {
                  font-size: 16px;
                  font-weight: 400;
                }
              }
            }
          }

          .photo {
            margin: 20px 15px 20px 20px;
            border-radius: 50%;
            svg {
              width: 100px;
              height: 100px;
            }
          }
        }
        .patient-icons {
          margin: 10px 12.5px 10px 12.5px;
        }
      }
    }
    .taches {
      overflow: hidden;
      margin-bottom: 45px;
      justify-content: space-between;
      flex-wrap: wrap;
      .tache {
        width: 266px;
        background: #ffffff;
        box-shadow: -11px 5px 40px -20px rgba(140, 151, 175, 0.4);
        border-radius: 20px;
        .header {
          margin-left: 20px;
          margin-right: 20px;
          margin-top: 7px;
          p {
            span {
              font-weight: 400;
            }
          }
          .sub-header {
            font-weight: bold;
            font-size: 18px;
            line-height: 21px;
          }
        }
        .subTitle {
          font-size: 13px;
          line-height: 15px;
          color: #a6a9c8;
          margin-left: 20px;
          margin-right: 20px;
        }
        .subTitle-doc {
          font-size: 13px;
          line-height: 15px;
          color: #a6a9c8;
          margin: 18px 20px 0px 20px;
        }
        .btn {
          margin-left: 12px;
        }
      }
      .tache-box {
        font-size: 14px;
        line-height: 16px;
        color: #a6a9c8;
        height: 35px;
        background: rgba(166, 169, 200, 0.22);
        border-radius: 9px;
        padding: 10px 15px 9px 14px;
        margin: 10px 0px 13px 20px;
      }
      .tacheboxContainer {
        margin-left: 20px;
        .tache-box {
          font-size: 14px;
          line-height: 16px;
          color: #a6a9c8;
          height: 35px;
          background: rgba(166, 169, 200, 0.22);
          border-radius: 9px;
          padding: 10px 15px 9px 14px;
          margin: 10px 0px 13px 0px;
          &:nth-child(odd) {
            margin-right: 11px;
          }
          p {
            margin-left: 7px;
          }
        }
      }
      .addObservation {
        color: #4565f6;
      }
      .traitementbtn {
        margin-top: 60px;
      }
    }
    .patient-tabs {
      background: #ffffff;
      border: 1px solid #eaecf4;
      border-radius: 15px;
      margin-bottom: 20px;
      .tabs {
        min-height: 50px;
        .tab {
          height: 42px;
          padding: 10px 12px 10px 12px;
        }
        .selected {
          background: #f5f5f9;
          border-radius: 15px;
          padding: 10px 12px 10px 12px;
          color: #4565f6;
        }
      }
    }
    .tabs-content {
      background: #f5f5f9;
      // box-shadow: -11px 5px 40px -20px rgba(140, 151, 175, 0.4);
      border-radius: 20px 20px 20px 20px;
      //height: 347px;
      padding: 20px 20px 20px 20px;
      .tabs-container {
        .tabs-liste {
          .item {
            .sub-item {
              &:nth-child(2) {
                margin-top: 38px;
                margin-bottom: 38px;
              }
              .title-container {
                line-height: 16px;
                .title {
                  font-weight: bold;
                  font-size: 20px;
                  line-height: 23px;
                }
                .card-subtitle {
                  margin-bottom: 8px;
                }
              }
              .values-patient {
                .sub-values {
                  margin-right: 50px;
                  margin-top: 8px;
                  .value {
                    background: #ffffff;
                    border-radius: 10px;
                    width: 47px;
                    height: 37px;
                    font-weight: bold;
                    font-size: 13px;
                    line-height: 15px;
                    color: #4565f6;
                    margin-left: 6px;
                    margin-right: 6px;
                  }
                  .name {
                    font-size: 16px;
                    line-height: 18px;
                  }
                }
                .imc {
                  p {
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 18px;
                    color: #a6a9c8;
                  }
                  .valImc {
                    width: 47px;
                    height: 37px;
                    background: #a6a9c8;
                    border-radius: 10px;
                    p {
                      font-weight: bold;
                      font-size: 13px;
                      line-height: 15px;
                      color: white;
                      padding: 11px 9px 11px 9px;
                    }
                  }
                }
              }
            }
          }
          .sub-documents {
            .doc-title {
              font-size: 20px;
              text-transform: uppercase;
              color: #827f9b;
              margin-bottom: 8px;
            }
            .images {
              img {
                width: 120px;
                height: 120px;
                border: 0.5px solid #dbdbdb;
                border-radius: 8px;
                &:nth-child(n-1) {
                  margin-right: 29px;
                }
              }
            }
            .title-doc {
              margin-top: 35px;
              .sub-title {
                font-size: 20px;
                line-height: 23px;
                text-transform: uppercase;
                color: #827f9b;
              }
              .btnAddtache {
                width: 103px;
                height: 32px;
              }
            }
          }
        }
      }
    }
  }
  .img {
    margin-right: 9px;
  }
  .title-taches {
    margin-top: 52px;
    margin-bottom: 37px;
    .sub-title {
      font-size: 20px;
      line-height: 23px;
      text-transform: uppercase;
      color: #827f9b;
    }
    .btnAddtache {
      width: 103px;
      height: 35px;
    }
  }
  .title-taches-inside-block {
    margin-bottom: 37px;
    .sub-title {
      font-size: 20px;
      line-height: 23px;
      text-transform: uppercase;
      color: #827f9b;
    }
    .btnAddtache {
      width: 103px;
      height: 35px;
      margin-right: 9px;
    }
    .alignedIcon {
      width: 32px;
      height: 32px;
      border-radius: 9px;
    }
  }
  .fleche {
    width: 32px;
    height: 32px;
    background: #ffffff;
    border-radius: 9px;
    margin-right: 12px;
  }
  .stretch {
    margin-top: 46px;
  }
}

.form-add-patient {
  .title {
    margin-top: 60px;
    margin-bottom: 34px;
  }
  .form-container {
    input:not([type="checkbox"]):not([type="radio"]) {
      width: 417px;
      height: 45px;
      padding: 10px 10px 11.5px 37px;
      border-radius: 9px;
      background: rgba(255, 255, 255, 0.5);
      border: 1px solid rgba(166, 169, 200, 0.28);
      &::placeholder {
        font-size: 14px;
        line-height: 18px;
        color: #a6a9c8;
        height: 18px;
      }
    }

    .inputa {
      // padding: 10px 10px 11.5px 37px;
      border-radius: 9px;
      background: rgba(255, 255, 255, 0.5);
      border: 1px solid rgba(166, 169, 200, 0.28);
      &::placeholder {
        font-size: 14px;
        line-height: 18px;
        color: #a6a9c8;
        height: 18px;
      }
    }
    .inputax {
      // padding: 10px 10px 11.5px 37px;
      border-radius: 9px;
      background: rgba(255, 255, 255, 0.5);

      &::placeholder {
        font-size: 14px;
        line-height: 18px;
        color: #a6a9c8;
        height: 18px;
      }
    }
    .sexe {
      margin-right: 60px;
    }
    .particularite-container {
      .particularite {
        .check {
          font-size: 14px;
          line-height: 16px;
          color: #a6a9c8;
          &:first-child {
            margin-left: 6px;
          }
        }
        &:nth-child(1) {
          margin-right: 14px;
        }
      }
    }

    .inputTest {
      margin-bottom: 14px;
    }
    .btn {
      width: 170px;
      height: 50px;
    }
  }
}
.firstModal {
  box-shadow: -11px 0px 40px -20px rgba(140, 151, 175, 0.4);
  border-radius: 20px;
  padding: 20px 40px 60px 40px;
  background: #ffffff;
  max-width: 700px;

  .buttons {
    margin-bottom: 17px;
    button {
      &:first-child {
        margin-right: 30px;
      }
    }
  }
  span {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #827f9b;
  }
}
.confirmAddPatientX {
  box-shadow: -11px 0px 40px -20px rgba(140, 151, 175, 0.4);
  border-radius: 20px;
  padding: 60px 40px 60px 40px;
  background: #ffffff;
  max-width: 521.79px;
  width: 80vw;
  .buttons {
    margin-bottom: 17px;
    button {
      &:first-child {
        margin-right: 30px;
      }
    }
  }
  h2 {
    font-size: 26px;
    line-height: 28px;
    text-align: center;
    color: #000000;
  }
  span {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #827f9b;
  }
}

.confirmAddPatient {
  box-shadow: -11px 0px 40px -20px rgba(140, 151, 175, 0.4);
  border-radius: 20px;
  padding: 60px 40px 60px 40px;
  background: #ffffff;
  max-width: 521.79px;
  width: 80vw;
  .buttons {
    margin-bottom: 17px;
    button {
      &:first-child {
        margin-right: 30px;
      }
    }
  }
  span {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #827f9b;
  }
}

.fixingColor {
  svg {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(130deg)
      brightness(104%) contrast(106%);
  }
}

.chat-box {
  .input-chat-box {
    border-radius: 10px;
    border: solid 1px rgba(166, 169, 200, 0.28);
    background-color: rgba(255, 255, 255, 0.5);
  }

  .avatar-chat {
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    width: 35px;
    height: 35px;
    flex-grow: 0;
  }
  .message-chat {
    flex-grow: 0;
    font-size: 16px;
    line-height: 1.25;
  }

  .you {
    color: white;
    background-color: var(--color-primary-blue);
    border-radius: 20px;
  }

  .hr-center-text {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: black;
    text-align: center;
    height: 1.5em;
    opacity: 0.5;
    &:before {
      content: "";
      // use the linear-gradient for the fading effect
      // use a solid background color for a solid bar
      background: linear-gradient(
        to right,
        transparent,
        var(--color-primary-grey),
        transparent
      );
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
    }
    &:after {
      content: attr(data-content);
      position: relative;
      display: inline-block;
      color: black;

      padding: 0 0.5em;
      line-height: 1.5em;
      // this is really the only tricky part, you need to specify the background color of the container element...
      color: var(--color-primary-grey);
      background-color: #fcfcfa;
    }
  }
}

.header-chat-patient {
  .infos {
    background: #f5f5f9;
    border-radius: 20px;
    .sub-infos {
      .details {
        font-size: 13px;
        margin-top: 20px;
        .imcBox {
          width: 94.22px;
          height: 31.02px;
          background: rgba(166, 169, 200, 0.22);
          border-radius: 9px;
        }
        .name {
          height: 23px;
          font-weight: bold;
          font-size: 20px;
          color: #000000;
        }
        .phonemail {
          &.div:first-child {
            margin-right: 30px;
          }
        }
        .mail {
          margin-left: 31px;
        }
        .birth {
          margin-bottom: 10px;
          .sub-birth {
            img {
              width: 17px;
              height: 17px;
            }
          }
          .dateBirth {
            p {
              font-size: 16px;
              font-weight: 400;
            }
          }
        }
      }
      .photo {
        width: 95px;
        height: 95px;
        margin: 20px 10px 20px 20px;
        border-radius: 20px;
        svg {
          width: 100px;
          height: 100px;
        }
      }
    }
    .patient-icons {
      margin: 10px 12.5px 10px 12.5px;
    }
  }
}
