.tabsContent {
  background: #f5f5f9;
  // box-shadow: -11px 5px 40px -20px rgba(140, 151, 175, 0.4);
  border-radius: 20px 20px 20px 20px;
  margin-top: 30px;
  width: 90%;
  padding: 2em;
  .flex-parent-element {
    display: flex;
  }
  //className={`day  flexCenter `}
  
  .flex-child-element {
    display: flex;
    padding:1em;
  }
}



