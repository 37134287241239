.card-specialist {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  max-width: 85vw;
  min-height: 123px;
  border-radius: 20px;
  background-color: #fff;
}
.card-specialistd {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  max-width: 85vw;
  min-height: 70px;
  border-radius: 20px;
  background-color: #fff;


}

.avatarMedecinChat {
  width: 60.9px;
  height: 60.9px;
  flex-grow: 0;
  border-radius: 20px;
  background-color: lightblue;
}

.chat-box {
  .input-chat-box {
    border-radius: 10px;
    border: solid 1px rgba(166, 169, 200, 0.28);
    background-color: rgba(255, 255, 255, 0.5);
  }

  .avatar-chat {
    border-radius: 50%;
    background-color: grey;
    width: 35px;
    height: 35px;
    flex-grow: 0;
  }
  .message-chat {
    flex-grow: 0;
    font-size: 16px;
    line-height: 1.25;
  }

  .me {
  }
  .you {
    color: white;
    background-color: var(--color-primary-blue);
    border-radius: 20px;
  }

  .hr-center-text {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: black;
    text-align: center;
    height: 1.5em;
    opacity: 0.5;
    &:before {
      content: "";
      background: linear-gradient(
        to right,
        transparent,
        var(--color-primary-grey),
        transparent
      );
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
    }
    &:after {
      content: attr(data-content);
      position: relative;
      display: inline-block;
      color: black;

      padding: 0 0.5em;
      line-height: 1.5em;
      color: var(--color-primary-grey);
      background-color: #fcfcfa;
    }
  }
}

.shared-folder {
  select {
    -webkit-appearance: none;
    appearance: none;
  }
  .select-wrapper {
    position: relative;
  }

  .select-wrapper::after {
    content: "▼";
    font-size: 1rem;
    top: 25%;
    right: 10px;
    color: grey;
    position: absolute;
  }

  .box-share-forder {
    flex-grow: 0;
    padding: 8px 29px 8px 29px;
    border-radius: 9px;
    background-color: rgba(166, 169, 200, 0.22);
    font-size: 14px;
    color: var(--color-primary-grey);
  }

  .box-selected {
    border: 1px solid var(--color-primary-blue);
    background-color: var(--color-primary-grey-background);
    color: var(--color-primary-blue);
  }
  .report-card {
    padding: 11px 12px 8px 10px;
    border-radius: 20px;
    background-color: #f2f2f7;
    width: 300px;
  }

  .updated-report {
    font-size: 13px;
    font-weight: bold;
    color: #a6a9c8;
  }
}

.share-folder-antecedent {
  margin-top: 30px;
  border-radius: 20px;
  padding: 11px 12px 30px 19px;
  box-shadow: -11px 5px 40px -20px rgba(140, 151, 175, 0.4);
  background-color: #f5f5f9;
  .card-antecedent {
    width: 300px;
    padding: 20px 6px 0 20px;
    border-radius: 20px;
    box-shadow: -11px 0 40px -20px rgba(140, 151, 175, 0.4);
    background-color: #fff;
  }
}
.blue-text {
  font-size: 16px;
  font-weight: 500;
  color: #4565f6;
}

.share-folder-traitement {
  margin-top: 30px;
  border-radius: 20px;
  padding: 11px 12px 30px 19px;
  box-shadow: -11px 5px 40px -20px rgba(140, 151, 175, 0.4);
  background-color: #f5f5f9;
  .card-traitement {
    width: 300px;
    padding: 10px 11.9px 12px 15px;
    border-radius: 20px;
    box-shadow: -11px 5px 40px -20px rgba(140, 151, 175, 0.4);
    background-color: #fff;
  }
  .blue-text {
    font-size: 16px;
    font-weight: 500;
    color: #4565f6;
  }
}
