.card-patient {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  min-width: 549px;
  min-height: 123px;
  border-radius: 20px;
  .patient-info {
    margin-bottom: 13px;
    .details{
      padding-top: 23px;
      .card-subtitle{
        margin-bottom: 11px;
        span{
          font-weight: 700;
        }
      }
    }
  }
  .patient-icons {
    margin-bottom: 13px;
    .icon-secondary{
      margin : 10px;
  }
  }
}
.selectedPatientDoctor{
  border: 3px solid #4565F6
}
