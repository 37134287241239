.agenda-config-form {
  h1 {
    text-align: center;
  }

  > .button-group-wrapper {
    flex-grow: 0;
    margin: 1.4em 2.6em;
    padding: 0.25em;
    border-radius: 1em;
    border: solid 1px #eaecf4;
    background-color: #fff;

    button {
      width: 9.5em;
      height: 2.9em;
      flex-grow: 0;
      margin: 0;
      border-radius: 15px;
      background-color: transparent;
      color: #a6a9c8;
    }

    button.active {
      background-color: #f5f5f9;
      color: #4565f6;
    }
  }
  .workingDays {
    margin-top: 15px;
    //margin-left: 233px;
    .day {
      max-height: 35px !important;
    }
    div[aria-checked="false"] {
      flex-grow: 0;
      padding: 10px 12px 9px 14px;
      border-radius: 9px;
      background-color: rgba(166, 169, 200, 0.22);
      font-size: 14px;
      color: var(--color-primary-grey);
    }
    div[aria-checked="true"] {
      flex-grow: 0;
      padding: 10px 12px 9px 14px;
      border-radius: 9px;
      background-color: rgba(137, 245, 137, 0.22);
      font-size: 14px;
      color: var(--color-primary-grey);
    }
    .flex-parent-element {
      display: flex;
      width: 50%;
      //max-height: 58px !important;
    }
    //className={`day  flexCenter `}

    .flex-child-element {
      flex: 1;
      border: 2px solid rgb(255, 255, 255);
      //margin: 10px;
    }

    .flex-child-element:first-child {
      margin-right: 20px;
    }
    .sub-values {
      .value {
        background: #ffffff;
        border-radius: 10px;
        width: 47px;
        height: 37px;
        font-weight: bold;
        font-size: 13px;
        line-height: 15px;
        color: #4565f6;
        margin-left: 6px;
        margin-right: 6px;
      }
    }
  }
  .plageHoraire {
    margin-top: 32px;
    .sub-values {
      .value {
        background: #ffffff;
        border-radius: 10px;
        width: 47px;
        height: 37px;
        font-weight: bold;
        font-size: 13px;
        line-height: 15px;
        color: #4565f6;
        margin-left: 6px;
        margin-right: 6px;
      }
    }
  }
  select {
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(166, 169, 200, 0.28);
    box-sizing: border-box;
    border-radius: 9px;
    width: 109px;
    height: 45px;
  }
  .checkboxCont {
    margin-top: 62px;
    margin-bottom: 27px;
  }
  .rdvTimeContainer {
    margin-top: 82px;
  }
  .dashedButton {
    margin-top: 55px;
    border: 1px dashed #a6a9c8;
    box-sizing: border-box;
    border-radius: 9px;
    max-width: 415px;
    .menu {
      color: #a6a9c8;
      padding: 15px 122px;
    }
  }
}
.leave-config-form {
  padding-top: 6px;
}

.flexCenterBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-gap: 16px;
}
.float-container {
  border: 3px solid rgb(0, 0, 0);
}

.float-child {
  width: 50%;
  float: left;
  padding: 20px;
  border: 2px solid #ff0000;
}
.new-event-appointment-form {
  > .button-group-wrapper {
    width: 298px;
    height: 18.1px;
    flex-grow: 0;
    margin: 0 0 4.9px;
    padding: 0 221px 0.1px 0;

    button {
      width: 8em;
      padding: 0.3em;
      flex-grow: 0;
      margin: 0 31px 0.1px 0;
      font-family: Ubuntu;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #a6a9c8;
      background-color: transparent;
    }

    button.active {
      color: #4565f6;
      border-bottom: 1px solid #4565f6;
    }
  }
}

.tabs-Container {
  //margin-top: 24px;
  .patient-tabs {
    background: #ffffff;
    //border: 1px solid #eaecf4;
    border-radius: 15px;
    margin-bottom: 20px;
    max-width: 348px;
    .tabs {
      min-height: 50px;
      .atab {
        height: 42px;
        padding: 10px 12px 10px 12px;
        color: #a6a9c8;
      }
      .selected {
        background: #f5f5f9;
        border-radius: 15px;
        padding: 12px 17px 12px 14px;
        color: #4565f6;
      }
    }
  }
}

.values-patient {
  .sub-values {
    margin-right: 50px;
    margin-top: 4px;
    width: 200px;

    .value {
      background: #ffffff;
      border-radius: 10px;
      width: 115px;
      height: 37px;
      font-weight: bold;
      font-size: 13px;
      line-height: 15px;
      color: #4565f6;
      margin-left: 30px;
      //margin-right: 6px;
    }
    .name {
      font-size: 16px;
      line-height: 18px;
    }
  }
}
